import styled from 'styled-components';
import React from 'react';
import { colors, spacing } from '@procore/core-react';

// Sidebar is deprecated from a style perspective, do not use it in new code
// this component exists as a backfill for core-react 11 -> 12 migration

const SidebarStyling = styled.div`
  border-left: 1px solid hsl(200, 8%, 85%);
  position: fixed;
  background-color: hsl(200, 8%, 96%);
  overflow: auto;
  z-index: 3;
  height: calc(100vh - 61px);
  top: 61px;
  width: 400px;
`;

export const SidebarLabel = styled.div`
  color: hsl(200, 8%, 15%);
  font-family: inherit;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 28px;
  margin: 0;
`;

export const SidebarHeader = styled.div`
  display: flex;
  padding: 0 ${spacing.xl}px ${spacing.md}px;
  align-items: center;
  justify-content: space-between;
`;

export const SidebarButton = styled.div`
  padding: ${spacing.sm}px ${spacing.lg}px 0px;
`;

export const Divider = styled.div`
  height: 0;
  margin: ${spacing.xl}px;
  border-top: 1px solid ${colors.gray85};
`;

export const Sidebar = ({ children }) => (
  <SidebarStyling>{children}</SidebarStyling>
);
